import React, { ComponentType, Suspense } from 'react';
import type { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from 'src/redux/modules/auth.selectors';
import Layout from './Layout';
import { redirectToLogin } from 'src/redux/modules/auth.actions';
import type { AppDispatch } from 'src/redux/store';

interface AuthenticatedRouteProps extends RouteComponentProps {
  component: ComponentType;
  pageTitle: string;
}

export default function AuthenticatedRoute({
  component: Component,
  location,
  pageTitle,
}: AuthenticatedRouteProps) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch<AppDispatch>();

  if (!isAuthenticated && location?.pathname !== `/login`) {
    dispatch(redirectToLogin());
    return null;
  }

  return (
    <Layout pageTitle={pageTitle}>
      <Suspense fallback={null}>
        <Component />
      </Suspense>
    </Layout>
  );
}
