import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Router } from '@reach/router';
import { getIsAuthenticated, getIsAuthScriptLoaded } from 'src/redux/modules/auth.selectors';
import { logout, scriptLoaded } from 'src/redux/modules/auth';
import LoginRoute from 'src/routes/login';
import AuthenticatedRoute from 'src/components/AuthenticatedRoute';
import type { AppDispatch } from 'src/redux/store';

const IndexRoute = React.lazy(() => import('src/routes/index'));
const DevelopmentRoute = React.lazy(() => import('src/routes/development'));
const PeopleRoute = React.lazy(() => import('src/routes/people'));
const ServiceDeskRoute = React.lazy(() => import('src/routes/service-desk'));
const ToolsRoute = React.lazy(() => import('src/routes/tools'));

const authScriptId = 'gsi';

export default function App() {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const isAuthScriptLoaded = useSelector(getIsAuthScriptLoaded);

  // There's no way to log out through the UI currently, so this gives us an escape hatch from the
  // browser console
  useEffect(() => {
    window.logout = () => {
      if (isAuthScriptLoaded) {
        window.google.accounts.id.disableAutoSelect();
      }

      dispatch(logout());
    };
  }, [dispatch, isAuthScriptLoaded]);

  useLayoutEffect(() => {
    document.body.classList.add('initialized');

    if (!document.getElementById(authScriptId)) {
      const authScript = document.createElement('script');
      authScript.id = authScriptId;
      authScript.src = 'https://accounts.google.com/gsi/client';
      authScript.onload = () => {
        dispatch(scriptLoaded());
      };
      document.body.appendChild(authScript);
    }

    if (isAuthenticated) {
      document.body.classList.add('authenticated');
    } else {
      document.body.classList.remove('authenticated');
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Router>
      <LoginRoute path="/login" />
      <AuthenticatedRoute path="/" component={IndexRoute} pageTitle="Overview" />
      <AuthenticatedRoute
        path="/development"
        component={DevelopmentRoute}
        pageTitle="Development Flow"
      />
      <AuthenticatedRoute path="/people" component={PeopleRoute} pageTitle="People At Timely" />
      <AuthenticatedRoute
        path="/service-desk"
        component={ServiceDeskRoute}
        pageTitle="Service Desk"
      />
      <AuthenticatedRoute path="/tools" component={ToolsRoute} pageTitle="Tools We Use" />
      <Redirect default from="*" to="/" noThrow />
    </Router>
  );
}
