import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import SlackIcon from 'src/svg/slack.svg';

const MenuItem = ({ item }) => {
  const isActiveRootPath =
    item.path === '/' && typeof window !== 'undefined' && window?.location?.pathname === '/';
  const isActive =
    isActiveRootPath ||
    (typeof window !== 'undefined' &&
      window?.location?.href?.includes(item.path) &&
      item.path !== '/');

  const linkClass = classNames(
    'px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:bg-gray-700 mr-2',
    {
      'text-white bg-gray-900': isActive,
      'text-gray-300 hover:text-white hover:bg-gray-700': !isActive,
    }
  );

  return (
    <Link to={item.path} className={linkClass}>
      {item.name}
    </Link>
  );
};

export const HorizontalMenu = () => {
  const menuItems = [
    { path: '/', name: 'Home' },
    { path: '/development', name: 'Development' },
    { path: '/people', name: 'People' },
    { path: '/tools', name: 'Tools' },
    { path: '/service-desk', name: 'Service Desk' },
  ];
  // max-w-7xl mx-auto
  return (
    <div>
      <nav className="bg-gray-800">
        <div className="px-4 lg:px-8">
          <div className="flex items-center h-16">
            <div className="flex-shrink-0">
              <img className="h-8 w-8" src="/favicon_64.png" alt="" />
            </div>
            <div className="ml-4 lg:ml-10 flex items-baseline">
              {menuItems.map((item, idx) => (
                <MenuItem item={item} key={idx} />
              ))}
            </div>
            <div className="ml-auto text-sm text-gray-300 text-right">
              <div>Anything broken?</div>
              <div className="inline-flex items-center">
                Contact
                <a
                  href="slack://channel?team=T02916K4Q&id=C3NAZCL80"
                  className="inline-flex items-center text-white hover:underline"
                >
                  <SlackIcon className="w-6 h-6" />
                  Frontend
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
