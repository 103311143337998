import React from 'react';
import MemoryLogo from 'src/images/memory.svg';

export default function InitialLayout({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="fixed z-50 top-0 bottom-0 right-0 left-0 align-center p-10 justify-center items-center flex flex-col overflow-hidden text-white">
      <MemoryLogo />
      {children}
    </div>
  );
}
