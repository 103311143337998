import React from 'react';
import styles from './index.module.scss';
import { HorizontalMenu } from '../Menu';
// import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { Transition } from '@headlessui/react';

interface Props {
  variant?: 'fullWidth' | 'centered';
  title?: string;
  pageTitle: string;
  children: React.ReactNode;
}

export default ({ children, title, pageTitle, variant = 'fullWidth' }: Props) => {
  return (
    <Transition className={classNames(styles.container)} appear show>
      <Helmet title={`${pageTitle} | Timely Dashboard`} />
      <HorizontalMenu />
      {!!title && (
        <header className="bg-white shadow z-10">
          <div
            className={classNames('mx-auto py-6', {
              //  sm:px-6 lg:px-8
              'px-6': variant === 'fullWidth',
              'max-w-7xl px-4': variant === 'centered',
            })}
          >
            <h2 className="text-3xl font-bold leading-tight text-gray-900">
              {title || 'Name me please'}
            </h2>
          </div>
        </header>
      )}
      {variant === 'centered' ? (
        <div className="mx-auto py-6 max-w-7xl px-4 h-full  w-full">{children}</div>
      ) : (
        children
      )}
      <div id="modal" />
    </Transition>
  );
};

export const Grid = ({ children }) => {
  return <div className={styles.grid}>{children}</div>;
};
