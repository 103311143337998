import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RouteComponentProps } from '@reach/router';
import { Transition } from '@headlessui/react';
import { navigate } from 'gatsby';
import InitialLayout from 'src/components/InitialLayout';
import { CredentialResponse, login } from 'src/redux/modules/auth';
import {
  getIsAuthenticated,
  getIsAuthScriptLoaded,
  getRedirectToAfterAuth,
} from 'src/redux/modules/auth.selectors';
import { AUTH_CLIENT_ID } from 'src/redux/modules/auth.utils';
import type { AppDispatch } from 'src/redux/store';

const gsiContainerId = 'gsi-container';

export default function LoginRoute(props: RouteComponentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const gsiContainerRef = useRef<HTMLDivElement>(null);
  const isAuthScriptLoaded = useSelector(getIsAuthScriptLoaded);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const handleCredentialResponse = useCallback(
    ({ clientId, credential }: CredentialResponse) => {
      dispatch(
        login({
          clientId,
          credential,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isAuthScriptLoaded || !gsiContainerRef.current) return;

    const gsiContainer = gsiContainerRef.current;

    window.google.accounts.id.initialize({
      auto_select: true,
      cancel_on_tap_outside: false,
      client_id: AUTH_CLIENT_ID,
      callback: handleCredentialResponse,
      itp_support: true,
      prompt_parent_id: gsiContainerId,
    });

    window.google.accounts.id.prompt(notification => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // This prevents an ugly layout shift since both the generic "Sign In" button and
        // "Sign in as ..." buttons are rendered at the same time for a single frame
        gsiContainer.classList.add('h-11', 'overflow-hidden');
        window.google.accounts.id.renderButton(gsiContainer, {
          size: 'large',
          text: 'signin',
        });
      }
    });
  }, [isAuthScriptLoaded, handleCredentialResponse, gsiContainerRef]);

  const redirectToAfterAuth = useSelector(getRedirectToAfterAuth);
  if (isAuthenticated) {
    navigate(redirectToAfterAuth);
    return null;
  }

  return (
    <Transition appear show>
      <InitialLayout>
        <div
          id={gsiContainerId}
          ref={gsiContainerRef}
          className="flex items-center justify-center"
        />
      </InitialLayout>
    </Transition>
  );
}
